// Contact

// Variables
$half-gutter: 8px;
$gutter: 16px;
$double-gutter: 32px;
$reservation-item-max-width: 320px;
$reservation-item-border-radius: 8px;
$dark: #000;

// Blocks
.contact-main {
  margin: $gutter $double-gutter;

  h3 {
    margin-bottom: $gutter;
  }

  article {
    margin-bottom: $double-gutter;
  }
}

.time-table-item {
  margin-bottom: $half-gutter;
}

.reservation-container {
  display: flex;
  flex-direction: column;
  margin-top: $gutter;
}

.reservation-item {
  align-items: center;
  border: 1px solid $dark;
  border-radius: $reservation-item-border-radius;
  gap: $half-gutter;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-width: $reservation-item-max-width;
  padding: $gutter;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $gutter;
  }
}
