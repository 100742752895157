// Red Wines

// Variables
$half-gutter: 4px;
$gutter: 8px;
$double-gutter: 16px;
$gutter-xl: 32px;
$menu-item-max-width: 320px;
$menu-item-border-radius: 8px;
$light: #fff;
$dark: #000;
$opacity: 0.1;
$details-font-size: 12px;
$allergen-size: 32px;
$header-height: 96px;

// Blocks
.red-wines-container {
  padding: $double-gutter $gutter-xl;
  max-width: 720px;
  margin: 0 auto;
}

.red-wine-title {
  text-align: center;
  margin-bottom: $double-gutter;
}

.red-wine-entry-title {
  background-color: $light;
  box-shadow: 0px 4px 8px rgba($light, 0.2);
  margin-bottom: $gutter;
  position: sticky;
  padding: $gutter 0;
  top: $header-height;
}

.red-wine-item {
  display: flex;
  gap: $double-gutter;
  margin-bottom: $double-gutter;
}

.red-wine-details-container {
  flex-grow: 1;
  width: 65%;
}

.red-wine-details {
  font-style: italic;
  font-size: $details-font-size;
}

.red-wine-volume {
  font-weight: 600;
}

.red-wine-prices {
  display: flex;
  gap: $gutter;
  flex-direction: column;
}

.red-wine-price {
  display: flex;
}

.red-wine-icon {
  flex-shrink: 0;
}

.red-wine-money {
  font-weight: 700;
}
