// Language Selector Button

// Variables
$gutter: 8px;
$double-gutter: 16px;
$flag-size: 32px;

//Blocks
.language-selector-button {
  border-radius: 50%;
  cursor: pointer;
  height: $flag-size;
  object-fit: cover;
  width: $flag-size;
}
