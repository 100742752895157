// Menu

// Variables
$gutter: 8px;
$double-gutter: 16px;
$gutter-xl: 32px;
$menu-item-max-width: 320px;
$menu-item-border-radius: 8px;
$dark: #000;

// Blocks
.menu-container {
  padding: $gutter-xl;
}

.menu-item {
  align-items: center;
  border: 1px solid $dark;
  border-radius: $menu-item-border-radius;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-width: $menu-item-max-width;
  margin: 0 auto;
  padding: $double-gutter;
  text-align: center;
  font-weight: 700;

  &:not(:last-child) {
    margin-bottom: $double-gutter;
  }
}

.menu-info {
  margin: auto;
  margin-top: $gutter-xl;
  max-width: $menu-item-max-width;
}

.menu-info-item {
  display: flex;

  &:last-child {
    margin-top: $double-gutter;
    text-align: center;
    font-weight: 500;
  }
}

.menu-legend-item {
  margin-top: $gutter;
  font-size: 12px;
  font-style: italic;
}

.menu-info-title {
  flex-grow: 1;
}

.menu-info-price {
  font-weight: 700;
}
