// Navigation

// Variables
$gutter: 8px;
$double-gutter: 16px;
$opacity: 0.8;
$logo-size: 64px;
$header-height: 96px;

// Colors
$dark: #000;
$light: #fff;

//Blocks
.header {
  align-items: center;
  background-color: $light;
  box-shadow: 0px 4px 8px rgba($dark, $opacity);
  display: flex;
  height: $header-height;
  padding: $double-gutter;
  position: sticky;
  top: 0;
  overflow: auto;
  z-index: 2;
}

.logo-container {
  flex-shrink: 0;
}

.logo {
  height: $logo-size;
  width: $logo-size;
}

.navigation {
  flex-grow: 1;
  margin: 0 $double-gutter;
}

.navigation-list {
  display: flex;
}

.navigation-list-item {
  margin: 0 $gutter;
}

.active-link {
  border-bottom: 1px solid $dark;
}
