// Home

// Variables
$gutter: 16px;
$double-gutter: 32px;

// Blocks
.main-home {
  h1,
  h2,
  h4 {
    margin: $gutter $double-gutter;
  }

  article {
    margin: 0 $double-gutter;
    margin-bottom: $double-gutter;

    p {
      margin-bottom: $gutter;
    }
  }

  h1 {
    font-size: 1.5em;

    @media (min-width: 420px) {
      font-size: 2em;
    }
  }

  h2 {
    font-size: 1.25em;

    @media (min-width: 420px) {
      font-size: 1.75em;
    }
  }
}

.banner {
  object-fit: cover;
  width: 100%;
}

.home-content {
  margin: 0 auto;
  max-width: 1024px;
}
