// Food

// Variables
$half-gutter: 4px;
$gutter: 8px;
$double-gutter: 16px;
$gutter-xl: 32px;
$menu-item-max-width: 320px;
$menu-item-border-radius: 8px;
$light: #fff;
$dark: #000;
$opacity: 0.1;
$details-font-size: 12px;
$allergen-size: 32px;
$header-height: 96px;

// Blocks
.food-container,
.sweet-wine-container {
  padding: 0 $gutter-xl;
  max-width: 720px;
  margin: 0 auto;
}

.food-item-wrapper {
  &:not(:last-child) {
    border-bottom: 1px solid rgba($dark, $opacity);
  }
}

.food-title,
.sweet-wine-title {
  background-color: $light;
  margin-bottom: $double-gutter;
  padding: $double-gutter 0;
  position: sticky;
  text-align: center;
  top: $header-height;
  z-index: 1;

  &-no-margin {
    margin-bottom: 0;
  }

  @media (min-width: 720px) {
    text-align: left;
  }
}

.food-sub-title {
  font-size: 0.8em;
  font-style: italic;
  font-weight: 300;
  margin-bottom: $double-gutter;
}

.food-item-container {
  display: flex;
  margin-bottom: $double-gutter;
  gap: $gutter;
}

.food-item-title-wrapper {
  flex-grow: 1;
}

.food-item-title {
  text-align: left;
}

.food-item-extra {
  font-size: $details-font-size;
  font-style: italic;
  font-weight: 300;
}

.food-allergen-container {
  display: flex;
  margin-top: $half-gutter;
}

.food-allergen-item {
  height: $allergen-size;
  object-fit: contain;
  width: $allergen-size;
}

.food-price {
  align-self: flex-start;
  font-weight: 700;
}

.sweet-wine-wrapper {
  display: flex;
  gap: $gutter;
  margin-bottom: $double-gutter;
}

.sweet-wine-item {
  flex-grow: 1;
}

.sweet-wine-details {
  font-size: $details-font-size;
  font-style: italic;
  font-weight: 300;
}

.sweet-wine-price {
  align-self: flex-start;
  display: flex;
  font-weight: 700;
  flex-shrink: 0;
}

.sweet-wine-volume {
  font-weight: 500;
}

.sweet-wine-extra-info {
  display: flex;
  gap: $gutter;
}

.separator {
  margin: $gutter-xl auto;
  border: none;
  border-top: 3px double rgba($dark, $opacity);
  color: rgba($dark, $opacity);
  overflow: visible;
  text-align: center;
  height: $half-gutter;
  width: calc(100% - 2 * $gutter-xl);

  &::after {
    background: $light;
    content: "§";
    padding: 0 $half-gutter;
    position: relative;
    top: -1 * $double-gutter;
  }
}
