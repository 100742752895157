// Footer

// Variables
$gutter: 8px;
$double-gutter: 16px;
$opacity: 0.8;

// Colors
$dark: #000;
$light: #fff;

//Blocks
.footer {
  align-items: center;
  color: $light;
  background-color: rgba($dark, $opacity);
  display: flex;
  flex-direction: column;
  padding: 2 * $double-gutter $gutter;
  justify-content: center;
  margin-top: $gutter;

  p {
    text-align: center;
    margin-bottom: $gutter;
  }
}

.footer-icon-container {
  display: flex;
  margin: $double-gutter 0;
}

.footer-icon {
  cursor: pointer;
  margin: 0 $gutter;
}

.language-selector {
  margin-top: $double-gutter;
}

.footer-info-container {
  display: flex;
  gap: $gutter;

  & svg {
    flex-shrink: 0;
  }
}
