// 404

// Variables
$gutter: 32px;

// Blocks
.not-found-container {
  display: flex;
  justify-content: center;
}

.not-found-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: $gutter;
}
