// Language Selector Modal

// Variables
$light: #fff;
$opacity: 0.95;
$gutter: 8px;
$double-gutter: 16px;
$animation-delay: 0.15s;

// Animations
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

// Blocks
.language-selector-modal {
  background-color: rgba($light, $opacity);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  visibility: hidden;
  will-change: visibility;

  &--active {
    animation: slideInLeft $animation-delay ease-in forwards;
  }
}

.language-selector-close-button {
  cursor: pointer;
  position: absolute;
  top: $double-gutter;
  right: $double-gutter;
}

.language-selector-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: $double-gutter;
  font-weight: bold;

  &:not(:last-child) {
    margin-bottom: $double-gutter;
  }
}
